import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import Page404 from "./pages/Page404";
import BookConsultation from "./pages/BookConsultation";
import { useLayoutEffect } from 'react'

import './index.css'

// This is to help react router v6 scroll to top any time the page
// is changed.
// See: https://stackoverflow.com/questions/70193712/how-to-scroll-to-top-on-route-change-with-react-router-dom-v6
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
  <Wrapper>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="services" element={<Services />} />
      <Route path="book" element={<BookConsultation />} />
      <Route path="*" element={<Page404 />}
      />
    </Routes>
    </Wrapper>
  </BrowserRouter>,
  rootElement
);