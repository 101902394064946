import VOSBSeal from '../assets/img/VOSBSeal.png'
import SieffMarinesPhoto from '../assets/img/SieffMarinesPhoto.jpeg'


export default function VeteranSymbol() {
    return (
      <section className="py-0 overflow-hidden">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          
            <h2 className="text-2xl text-center font-extrabold text-gray-900 my-12">
            Proud To Be A Veteran-Owned Small Business (VOSB)</h2>
            
  
          <div className="relative">
            <img
              className="mx-auto h-48"
              src={VOSBSeal}
              alt="VOSB Seal"
            />
            <blockquote className="mt-10">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 text-gray-400 italic">
                <p>
                  &ldquo;Our mission is to educate and empower our clients to thrive in the modern digital landscape while promoting wellness in our community.&rdquo;
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={SieffMarinesPhoto}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-gray-900">Jesse Sieff</div>
  
                    <svg className="hidden md:block mx-1 h-5 w-5 text-sieffblue" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>
  
                    <div className="text-base font-medium text-gray-500">CEO, Sieff Studios</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    )
  }
  