import React, {useState, useEffect} from 'react'
import SieffStudiosProcess from '../assets/img/SieffStudiosProcess.png'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import VideoProductionServices from '../components/VideoProductionServices'
import CreativeServices from '../components/CreativeServices'
import CorporateMediaStrategies from '../assets/img/CorporateMediaStrategies.png'
import CTA from '../components/CTA'

import useWindowSize from '../hooks/useWindowResize'
import ActiveCampaignForm from '../components/ActiveCampaignForm'
import VeteranSymbol from '../components/VeteranSymbol'
import AboutSection from '../components/AboutSection'


export default function Contact() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start '>
    <Navbar showVideo={false}/>
    <div className='w-full mt-20 flex flex-col justify-center items-center'>
      <VeteranSymbol />
        <AboutSection />
        <Footer />
      </div>
    </div>
  )
}
