import React, {useState, useEffect} from 'react'
import ThankYouMessage from "./ThankYouMessage"

export default function ActiveCampaignSource() {

    const [companyName, setCompanyName] = useState('')
    const [titleValue, setTitleValue] = useState('')
    const [industryValue, setIndustryValue] = useState('')

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
  
    const handleSubmit = event => {
      setLoading(true)
      setError('')
      event.preventDefault()
      const data = new FormData(event.target)
      console.log(data)
      fetch("https://sieffstudios.activehosted.com/proc.php", {
        method: "POST",
        body: data,
        mode: "no-cors",
      })
        .then(res => {
          console.log(res)
          setFormSubmitted(true)
        })
        .catch(error => {
          console.log("Request failed", error)
          setError("Server Error - Check your Info and Try Again.")
        
        })
    }
  
    const loadingSVG = (
    <div className='w-full flex justify-center'>
              <svg role="status" className="mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-sieffblue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg></div>)

  return (
    <div className='w-full flex justify-center items-center p-4'>

        {formSubmitted ? <div className='w-full p-4'>
            <ThankYouMessage />
          </div> :
        
  <form id="_form_3_" className="_form _form_3 _inline-form  _dark" novalidate onSubmit={e => handleSubmit(e)}>
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="u" value="3" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="f" value="3" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="s" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="c" value="0" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="m" value="0" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="act" value="sub" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="v" value="2" />
    <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="hidden" name="or" value="8bfd74a391531ba8806566ea80902580" />
    <div className="_form-content">
      <div className="_form_element _x86389364 _full_width _clear" >
        <div className="_form-title text-3xl font-extrabold text-gray-900 mb-4 mt-6">
          Join the Sieff Studios Community
        </div>
      </div>
      <div className="_form_element _x25483730 _full_width _clear" >
        <div className="_html-code mb-6">
          <p>
            Stay up to date with our latest projects, or get in contact with our team to start yours!
          </p>
        </div>
      </div>
      <div className="_form_element _x40154632 _full_width " >
        <label for="firstname" className="_form-label text-sm font-bold">
          First Name*
        </label>
        <div className="_field-wrapper">
          <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="firstname" name="firstname" placeholder="Type your first name" required/>
        </div>
        {/* <!--  This STARTS the Custom Objects section  --> */}
        </div>
        <div className="_form_element _x66358375 _full_width " >
          <label for="lastname" className="_form-label text-sm font-bold">
            Last Name*
          </label>
          <div className="_field-wrapper">
            <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="lastname" name="lastname" placeholder="Type your last name" required/>
          </div>
          {/* <!--  This STARTS the Custom Objects section  --> */}
          </div>
          <div className="_form_element _x46468279 _full_width " >
            <label for="email" className="_form-label text-sm font-bold">
              Email*
            </label>
            <div className="_field-wrapper">
              <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="email" name="email" placeholder="Type your email" required/>
            </div>
            {/* <!--  This STARTS the Custom Objects section  --> */}
            </div>
            <div className="_form_element _x13127676 _full_width " >
              <label for="phone" className="_form-label text-sm font-bold">
                Phone
              </label>
              <div className="_field-wrapper">
                <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="phone" name="phone" placeholder="Type your phone number" />
              </div>
              {/* <!--  This STARTS the Custom Objects section  --> */}
              </div>
              <div className="_form_element _field2 _full_width " >
                <label for="field[2]" className="_form-label text-sm font-bold">
                  Company
                </label>
                <div className="_field-wrapper">
                  <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="field[2]" name="field[2]"
                  value={companyName}
                  onChange={(e)=>setCompanyName(e.target.value)}
                  placeholder="Your Company Name" />
                </div>
              </div>
              <div className="_form_element _field3 _full_width " >
                <label for="field[3]" className="_form-label text-sm font-bold">
                  Title*
                </label>
                <div className="_field-wrapper">
                  <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="field[3]" name="field[3]"
                  value={titleValue}
                  onChange={(e)=>setTitleValue(e.target.value)}
                  placeholder="Your Role at Your Company" required/>
                </div>
              </div>
              <div className="_form_element _field4 _full_width " >
                <label for="field[4]" className="_form-label text-sm font-bold">
                  Industry*
                </label>
                <div className="_field-wrapper">
                  <input className='rounded-md focus:outline-none focus:border-sieffblue w-full mb-3' type="text" id="field[4]" name="field[4]"
                  value={industryValue}
                  onChange={(e)=>setIndustryValue(e.target.value)}
                  placeholder="The Industry Your Company Serves" required/>
                </div>
              </div>
              <div className="_button-wrapper _full_width">
                <button id="_form_3_submit" className="_submit w-full bg-sieffblue hover:bg-sieffblue/80 text-white flex items-center justify-center p-2 rounded-md mt-6" type="submit">
                {loading ? loadingSVG:'Submit'}
                </button>
              </div>
              <div className="_clear-element">
              </div>
            </div>
            <div className="_form-thank-you" style={{display:'none'}}>
            </div>
          </form>}
    </div>
  )
}
