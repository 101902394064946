import React, { useState } from "react"
import ThankYouMessage from "./ThankYouMessage"

const ActiveCampaignForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = event => {
    setLoading(true)
    setError('')
    event.preventDefault()
    const data = new FormData(event.target)
    fetch("https://sieffstudios.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors",
    })
      .then(res => {
        console.log(res)
        setFormSubmitted(true)
      })
      .catch(error => {
        console.log("Request failed", error)
        setError("Server Error - Check your Info and Try Again.")
      
      })
  }

  const loadingSVG = (
  <div className='w-full flex justify-center'>
            <svg role="status" class="mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-sieffblue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg></div>)

  return (
      <div className='relative w-full'>
        {formSubmitted ? <div className='w-full p-4 absolute top-0 left-[50%] translate-x-[-50%]'>
            <ThankYouMessage />
          </div> :
          
    <form className='p-4 absolute top-0 left-[50%] translate-x-[-50%]' onSubmit={e => handleSubmit(e)}>
    <input type="hidden" name="u" value="3" />
    <input type="hidden" name="f" value="3" />
    <input type="hidden" name="s" />
    <input type="hidden" name="c" value="0" />
    <input type="hidden" name="m" value="0" />
    <input type="hidden" name="act" value="sub" />
    <input type="hidden" name="v" value="2" />
    <input type="hidden" name="or" value="23b3a8e446a72ea6772acbff96b01608" />
    <div className="_form-content">
      <div className="flex flex-col items-center justify-center" >
        
        <h2 className="text-3xl font-extrabold text-gray-900 mb-4 mt-12">
          Join the Sieff Studios Community</h2>
      </div>
      <div className="" >
        <div className="_html-code">
          <p>
            Stay up to date with our latest projects, or get in contact with our team to start yours!
          </p>
        </div>
      </div>
      <div className="_form_element _x99234263 _full_width mt-6 w-full flex flex-col" >
        <label for="fullname" className="text-sm font-bold ">
          Full Name*
        </label>
        <div className="w-full">
          <input className='w-full rounded-sm border-gray-300 focus:outline-none outline-0 focus:border-sieffblue' type="text" id="fullname" name="fullname" placeholder="Type your name" required/>
        </div>
        </div>
        <div className="_form_element _x46468279 _full_width mt-6 w-full flex flex-col " >
          <label for="email" className="text-sm font-bold ">
            Email*
          </label>
          <div className="w-full">
            <input className='w-full rounded-sm border-gray-300 focus:outline-none  outline-0 focus:border-sieffblue' type="text" id="email" name="email" placeholder="Type your email" required/>
          </div>
          </div>
          <div className="_form_element _field1 _full_width mt-6 w-full flex flex-col " >
            <label for="field[1]" className="text-sm font-bold ">
              Brief Description of What You Need (Optional)
            </label>
            <div className="w-full">
              <textarea className='w-full rounded-sm border-gray-300 focus:outline-none  outline-0 focus:border-sieffblue' id="field[1]" name="field[1]" placeholder="Ex: I need a team of qualified video professionals to livestream an event."  ></textarea>
            </div>
          </div>
          <div className="_button-wrapper w-full flex flex-col items-center justify-center">
            <div className=' mt-9 text-red-600'>
            {error}
            </div>
            <button id="_form_3_submit" className="w-full bg-sieffblue text-white p-2 rounded-sm m-6" type="submit">
              {loading ? loadingSVG:'Submit'}
            </button>
          </div>
          <div className="_clear-element">
          </div>
        </div>
        <div className="_form-thank-you" style={{display:'none'}}>
        </div>
      </form>
      
      }
      </div>
  )
}

export default ActiveCampaignForm