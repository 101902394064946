import React from 'react'
import BelmontHouseThumb from '../assets/video/BelmontHouseThumbnail.gif'
import BeyondTheBlackoutThumb from '../assets/video/BeyondTheBlackoutThumbnail.gif'
import DURDOUXThumbnail from '../assets/video/DURDOUXThumbnail.gif'
import PrincipalJetsThumbnail from '../assets/video/PrincipalJetsThumbnail.gif'
import TheWashingtonTattooThumbnail from '../assets/video/TheWashingtonTattooThumbnail.gif'
import Flowers from '../assets/video/Flowers.gif'
import Whiskey from '../assets/video/Whiskey.gif'
import Marines from '../assets/video/Marines.gif'
import BrandYourself from '../assets/video/BrandYourself.gif'
import CTA from './CTA'


export default function VideoDisplayGrid() {

    const videoContainerClass = 'flex m-6 cursor-pointer object-cover items-center justify-center'

    const srcList = [
        BelmontHouseThumb,
        BeyondTheBlackoutThumb,
        DURDOUXThumbnail,
        PrincipalJetsThumbnail,
        TheWashingtonTattooThumbnail,
        Flowers,
        Whiskey,
        Marines,
        BrandYourself,
    ]

    const linkList = [
        'https://vimeo.com/514403097',
        'https://vimeo.com/479927247',
        'https://vimeo.com/452013175',
        'https://vimeo.com/514454164',
        'https://vimeo.com/482369318',
        'https://vimeo.com/571759142',
        'https://www.youtube.com/watch?v=hrOU66CzeD0',
        'https://vimeo.com/453818525',
        'https://vimeo.com/611958025',
    ]
  return (
      <div className='flex flex-col items-center justify-center m-0 sm:mx-6 xl:mx-12 bg-gray-50'>
          <h1 className='text-xl font-bold m-12'>Featured Work</h1>
          
    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 dense '>

        {srcList.map((item,index) =>{
            return (
            <div className={videoContainerClass}>
                <a href={linkList[index]} target='_blank' rel='noopener noreferrer'>
                <div className='w-full h-full flex items-center justify-center'>
                <img className='object-cover' src={item} />
                </div>
                </a>
            </div>
            )})}
        
    </div>
    <div className='m-12 mb-24'>
        <CTA text="Start Your Project"/>
    </div>
    </div>
  )
}
