import React from 'react'
import { Link } from 'react-router-dom'
import SmallBlackLogo from '../assets/img/SmallBlackLogo.png'

export default function ThankYouMessage() {

    
  return (
    <div className='flex flex-col items-center justify-center'>
        <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Thank You!
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Please confirm your email in the link we sent you, and a member of our team will be in contact with you shortly!
          </p>
          <Link  className='mt-12 w-[50%] cursor-pointer hover:underline' to='/'>
          <div>
          <img  src={SmallBlackLogo} alt='logo' />
          <p className="mt-4 max-w-3xl mx-auto text-center text-sm text-gray-500">
            Back to Home
          </p>
          </div>
          </Link>
    </div>
  )
}
