import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import useWindowSize from '../hooks/useWindowResize'
import CalendlyWidget from '../components/CalendlyWidget'


export default function Contact() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start overflow-hidden'>
    <Navbar showVideo={false}/>
    <div className='w-full mt-20 flex flex-col justify-center items-center'>
      <CalendlyWidget />
      
      <Footer />
      </div>

    </div>
  )
}
