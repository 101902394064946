import React, {useState, useEffect} from 'react'
import LargeBlackLogoWithSub from '../assets/img/LargeBlackLogoWithSub.png'

export default function SieffTitle({showVideo}) {

    const [hasClickedArrow, setHasClickedArrow] = useState(false)

    
    const handleArrowClick = () => {
        window.scrollBy({ 
            top: window.innerWidth > 500 ? window.innerHeight-200 : window.innerHeight-100,
            left: 0, 
            behavior: 'smooth' 
          });
          setHasClickedArrow(true)
    }
    
    const [arrowBounce, setArrowBounce] = useState(false)
    const [arrowClass, setArrowClass] = useState('w-full flex justify-center items-center text-center z-10 text-black rounded-full cursor-pointer p-4 absolute bottom-[50px]')

    useEffect(() => {
        if (arrowBounce) setArrowClass('w-full flex justify-center items-center text-center animate-bounce z-10 text-black rounded-full cursor-pointer p-4 absolute bottom-[50px]')
    }, [arrowBounce])
   
    
    useEffect(() => {
        setTimeout(function() {
            setArrowBounce(true)
          }, 6000);
    }, [])

    
    


    return (
        <div className='relative h-screen flex flex-col items-center justify-center'>
            <div className='
            inset-0
            flex
            justify-center
            items-center
            
            w-screen'>
                <div className='w-[50vw] flex z-10' >
                <img style={{objectFit:'contain'}} className='flex' src={LargeBlackLogoWithSub} alt='Sieff Studios' />
                </div>
            </div>
            {!hasClickedArrow &&
                <div
                    className={arrowClass}
                    onClick={handleArrowClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
            </div>}
            </div>
    )
}
