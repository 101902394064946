import React from "react";
import { InlineWidget } from "react-calendly";

const CalendlyWidget = () => {
  return (
    <div className="w-full">
      <div className='overflow-clip'>
      <InlineWidget styles={{height: '1150px'}} url="https://calendly.com/sieffstudios/strategy_session?month=2022-04" />
      </div>
    </div>
  );
};

export default CalendlyWidget;