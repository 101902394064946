import React from 'react'
import { Link } from 'react-router-dom'

export default function CTA({text, consultation}) {
  return (
    <Link to={consultation? '/book' : '/contact'}>
    <button className='z-0 bg-sieffblue transition-color duration-150  hover:bg-sieffblue/80 p-2 px-6 rounded-md text-white flex items-center text-lg font-bold'>
      <span className='mr-2'>{text}</span>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </svg>
    </button>
    </Link>
  )
}
