/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Consulting, Storyboard, Video Strategy',
    description: 'Let us help you develop your vision.',
  },
  {
    name: 'Brand Analysis, Market Research, Marketing Campaigns',
    description: 'We can do the "dirty work" for you.',
  },
  {
    name: 'Professional Coaching & Business Resources',
    description: "As working creatives, we know how easy it is to get burnt out. We're happy to help you and your employees to stay excited!",
  }
]

export default function CreativeServices() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:pt-24 lg:px-8 pt-12">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Not sure where to start? </h2>
          <h2 className='text-3xl font-extrabold text-sieffblue'>We can help.</h2>
          <p className="mt-4 text-lg text-gray-500">
            We work with organizations large and small--and are able to help you even if you aren't exactly sure what you need.
          </p>
        </div>
        <dl className="my-6 mx-0 flex flex-col md:flex-row items-start justify-center">
          {features.map((feature) => (
            <div key={feature.name} className="relative md:max-w-[25%] mx-6">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 mb-12 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
