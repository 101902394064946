import React, {useState, useEffect} from 'react'
import SieffStudiosProcess from '../assets/img/SieffStudiosProcess.png'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import VideoProductionServices from '../components/VideoProductionServices'
import CreativeServices from '../components/CreativeServices'
import CorporateMediaStrategies from '../assets/img/CorporateMediaStrategies.png'
import CTA from '../components/CTA'

import useWindowSize from '../hooks/useWindowResize'


export default function Services() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start'>
    <Navbar showVideo={false}/>
    <div className='w-full mt-20 flex flex-col justify-center items-center'>
      <VideoProductionServices />
      <CTA text={"Meet Your Team"}/>
      <div className='max-w-xl mt-24'>
        <img src={CorporateMediaStrategies} alt='contact-info' />
      </div>
      <CreativeServices />
          {size.width > 500 && <h2 className="text-3xl font-extrabold text-gray-900">Our Process:</h2>}
          {size.width > 500 && <div className='max-w-7xl my-6'>
            <img src={SieffStudiosProcess} alt='process-diagram' />
          </div>}
      <CTA text={"Get Started"}/>
      <Footer />
      </div>
    </div>
  )
}
