import React,{ useRef, useEffect, useState } from 'react'
import CTA from '../components/CTA'
import QuoteSegment from '../components/QuoteSegment'
import SieffTitle from '../components/SieffTitle'
import Navbar from '../components/Navbar'
import VideoDisplayGrid from '../components/VideoDisplayGrid'
import VideoHero from '../components/VideoHero'
import CompanyPartners from '../components/CompanyPartners'
import Footer from '../components/Footer'

export default function Landing() {

  const [showVideo, setShowVideo] = useState(true)

  useEffect(() => {
    const cutoffTime = localStorage.getItem('movieCutoffTime')
    const currentTime = Math.floor( Date.now() / 1000 )
    if (cutoffTime) {
      if (currentTime<cutoffTime) {
        setShowVideo(false)
      }
    }

    localStorage.setItem('movieCutoffTime',currentTime+3600)
  }, [])
  
  useEffect(() => {
    console.log('showVideo',showVideo)
  }, [showVideo])
  


  return (
    <div className='overflow-hidden'>
      <Navbar showVideo={showVideo}/>
      {showVideo && <VideoHero />}
      <SieffTitle showVideo={showVideo}/>
      <QuoteSegment />
      <CompanyPartners />
      <VideoDisplayGrid />
      <Footer />
      
    </div>
  )
}
