import React, {useState, useEffect} from 'react'
import useDebouncedEffect from '../hooks/useDebouncedEffect';
import IntroAnim from '../assets/video/IntroAnim.mp4'
import useWindowSize from '../hooks/useWindowResize';

export default function VideoHero({parentcb}) {

  const size = useWindowSize()

    
    const [offset, setOffset] = useState(0);
    const [videoIsVisible, setVideoIsVisible] = useState(true);
    const [hideVideo, setHideVideo] = useState(false)

    const [hideVideoTrigger, setHideVideoTrigger] = useState(false)
    
        
    useDebouncedEffect(() => {
        setHideVideoTrigger(!hideVideoTrigger);
        if (videoIsVisible) {
          const onScroll = () => setOffset(window.pageYOffset);
          // clean up code
          window.removeEventListener('scroll', onScroll);
          window.addEventListener('scroll', onScroll, { passive: true });
          return () => window.removeEventListener('scroll', onScroll);
        }
    }, [videoIsVisible], 1000)

    useEffect(() => {
        if (!videoIsVisible) setHideVideo(true);
    }, [hideVideoTrigger])

    const [videoIsFinished, setVideoIsFinished] = useState(false)

    useEffect(() => {
      // Do this instead
      if (videoIsVisible) {

        setTimeout(function() {
          setVideoIsFinished(true)
        }, 6000);
      }

    }, []);

    useEffect(() => {
      if (videoIsFinished) {
        setVideoIsVisible(false)
        handleArrowClick()
      }
    }, [videoIsFinished])
    

    useEffect(() => {
      if (offset < 1 && !hideVideo) {
        setVideoIsVisible(true)
      } else { 
        if (videoIsVisible) {
            setVideoIsVisible(false)
        }
      }
    }, [offset])

    const [containerClassName, setContainerClassName] = useState('transition-all opacity-100 duration-[2000ms]')
    
    useEffect(() => {
        if (videoIsVisible) {
            setContainerClassName('transition-all opacity-100 duration-[2000ms] z-50')
        } else {
            setContainerClassName('transition-all opacity-0 duration-[2000ms] z-30')
        }
    }, [videoIsVisible])

    const handleArrowClick = () => {
        // window.scrollBy({ 
        //     top: window.innerWidth > 500 ? window.innerHeight-200 : window.innerHeight-100,
        //     left: 0, 
        //     behavior: 'smooth' 
        //   });
    }
    

  return (
    <div className={containerClassName}>
        <div className='
            fixed
            inset-0
            flex
            justify-center
            items-center
            w-screen
            bg-black
            z-40
            text-white'>
            <video onEnded={() => setVideoIsFinished(true)} className={videoIsFinished ? 'opacity-0 transition-all duration-[2000ms]  flex object-fit ' : 'opacity-100 transition-all duration-[2000ms] flex object-fit'} width={size.width} autoPlay muted >
              <source className='flex object-fit' src={IntroAnim}  type="video/mp4"/>
          </video>
            
            </div>
            {/* <div className='w-screen absolute bottom-0 flex flex-col items-center justify-center mb-6'>

                  <div className={videoIsFinished ? 'opacity-100 transition-all duration-[2000ms] z-50 text-white rounded-full hover:bg-gray-800 cursor-pointer p-4' : 'opacity-0 transition-all duration-[2000ms] z-50 text-white rounded-full hover:bg-gray-800 cursor-pointer p-4'} onClick={handleArrowClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
            </div> */}
        
    </div>
  )
}
