import { useEffect } from "react";

export default function useDebouncedEffect(fn, deps, time) {
    const dependencies = [...deps, fn, time] 
    useEffect(() => {
      const timeout = setTimeout(fn, time);
      return () => {
        clearTimeout(timeout);
      }
    }, dependencies);
  }