/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import { MicrophoneIcon, VideoCameraIcon } from '@heroicons/react/solid'
import SieffStudiosTeam from '../assets/img/SieffStudiosTeam.JPG'

const transferFeatures = [
  {
    id: 1,
    name: 'On-site Video + Audio Recording',
    description:
      'Let us help you make lasting impressions, elicit emotions, and compel your audience to action  through captivating stories and brilliant media.',
    icon: VideoCameraIcon,
  },
  {
    id: 2,
    name: 'LIVE Streaming + Event Coverage',
    description:
    'With a profound respect for time and attention, Sieff Studios is committed to delivering our clients an enriching experience at every opportunity. Our commitment to building great experiences, memories, and relationships extends far beyond our work and serves as the guiding principals of how we operate.',
    icon: LightningBoltIcon,
  },
  {
    id: 3,
    name: 'Post Production + Editing',
    description:
    "We specialize in producing media designed specifically to support the most impactful areas of your business, solve as many problems as possible, and free you up to focus on the exciting new road ahead.",
      
    icon: ScaleIcon,
  },
  {
    id: 4,
    name: 'Podcast & Social Media Production',
    description:
      'It is our mission to empower creators to tell their stories in the most compelling way way possible. Our production team has the experience to bring out the best version of you, for your audience.',
    icon: MicrophoneIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'Mobile notifications',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Reminder emails',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: MailIcon,
  },
]

export default function VideoProductionServices() {
  return (
    <div className="py-16  overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-sieffblue/10" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Video Production
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500 italic">
          Communicate effectively. Educate efficiently. Inspire genuinely.
          </p>
          <p  className="mt-2 max-w-3xl mx-auto text-center text-md text-gray-500 italic">--Jesse + Team</p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">


            
          <div className="my-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            
            <img
              className="relative mx-auto shadow-lg"
              width={490}
              src={SieffStudiosTeam}
              alt=""
            />
          </div>

          
          <div className="relative order-last">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Your own media team, made up of the industry’s top creative professionals.
            </h3>
            <p className="mt-3 text-lg text-gray-500">
            
            We specialize in producing media designed specifically to support the most impactful areas of your business, solve as many problems as possible, and free you up to focus on the exciting new road ahead.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sieffblue text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-sieffblue/10" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

      </div>
    </div>
  )
}
