import React, {useState, useEffect} from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import useWindowSize from '../hooks/useWindowResize'
import ActiveCampaignForm from '../components/ActiveCampaignForm'
import ActiveCampaignSource from '../components/ActiveCampaignSource'
import ActiveCampaignRaw from '../components/ActiveCampaignRaw'


export default function Contact() {

  const size = useWindowSize();
  return (
    <div className='flex flex-col justify-center items-start'>
    <Navbar showVideo={false}/>
    <div className='w-full mt-20 flex flex-col justify-center items-center'>
      {/* <ActiveCampaignForm /> */}
      <ActiveCampaignSource />
      {/* <ActiveCampaignRaw /> */}
      <div className='mt-[900px]'>
      <Footer />
      </div>
      </div>
    </div>
  )
}
