import React, {useState, useEffect} from 'react';
import MarineHandshake from '../assets/img/MarineHandshake.jpg'
import CTA from './CTA';

export default function AboutSection() {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-3xl mx-auto p-4">
            <h1 className='flex items-center'>
                <div className='flex-grow-1 m-4 h-2 mt-6 w-full  text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
              <span className="mt-2 w-24 sm:w-72 text-2xl text-center font-bold tracking-tight text-gray-900">
                    About Us
              </span>
              <div className='flex-grow-1 m-4 h-2 mt-6 w-full text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
            </h1>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Located near Annapolis, MD, Sieff Studios was built upon a foundation of service and a passion
                for helping others discover their own creative potential. We operate under a consultative business
                model and prioritize the essential needs and goals of our clients first. Mindful of these goals,
                we work together to build a clearly defined action plan to guide us through the phases of each unique project.</p>
            <p className="mt-8 text-lg text-gray-500 leading-8">
                Sieff Studios utilizes a strong network of trusted consultants, designers, and business resources — allowing us to create customized solutions that are both proportionate and scalable. This personalized, results-based approach maximizes efficiency, efficacy, and overall ROI for our clients. Offering customized solutions helps reduce any unnecessary overhead costs, which not only allows us offer competitive pricing compared to larger production companies,
                but also more easily fulfill our mission by <span className='font-bold'>building better relationships with our clients. </span>
            </p>
            <div>
            <h1 className='flex items-center mt-12'>
                <div className='flex-grow-1 m-4 h-2 mt-6 w-full  text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
              <span className="mt-2 w-[200px] sm:w-[500px] text-2xl text-center font-bold tracking-tight text-gray-900">
                    Our Founder
              </span>
              <div className='flex-grow-1 m-4 h-2 mt-6 w-full text-transparent bg-transparent border-2 border-tranparent border-b-gray-200 border-t-0 border-x-0'></div>
            </h1>
            
              </div>
          </div>
          <div className="mt-6  max-w-3xl p-6">
          <img
                className="w-full rounded-lg"
                src={MarineHandshake}
                alt=""
              />
              <div className='w-full text-center p-2 text-xs italic text-gray-500'>Jesse Sieff receiving Marine Corps Acheivement Medal for his work on Media Outreach at the Public Affairs Office </div>
            
          <p className="mt-8 text-lg text-gray-500 leading-8">
            Jesse Sieff, owner and founder of Sieff Studios, began his career as a professional musician, teacher, and composer.
            A proud veteran of the U.S. Marine Corps, Jesse passionately applied a synthesis of his creative background directly
            to video production during his role as musician an NCOIC of the U.S. Marine Drum & Bugle Corps Office of Public Affairs.
            Upon the honorable completion of his enlistment with the Marines, Jesse was awarded the Navy & Marine Corps
            Achievement Medal for his massively positive impact on the company’s digital media assets, quality standards, and
            outreach effectiveness.</p>
            
            <p className="mt-8 text-lg text-gray-500 leading-8">
            Additionally, he holds a Bachelor of Arts degree from the Indiana University of Pennsylvania,
            an Associates Degree in Computer Aided Drafting and Design from the Community College of Allegheny County, and a
            NXLevel Business Certification from a business development and entrepreneurship training program for veterans
            know as Project Opportunity.  Jesse now continues his work independently through Sieff Studios — working with a talented team dedicated to produce exceptional digital assets for businesses in the Washington-Baltimore communities and across the greater DMV area.
            </p>
          </div>
          <div className='w-full flex justify-center pt-6'>
          <CTA text="Book a Consultation" consultation={true}/>
          </div>
        </div>
      </div>
    )
  }
  