import TWT_REV from '../assets/partner-logos/TWT_REV.png'
import DCModernLuxury from '../assets/partner-logos/DCmodernluxury.png'
import Sheffield from '../assets/partner-logos/sheffield.png'
import NBC from '../assets/partner-logos/nbc-logo-1.png'
import NorthropRealty from '../assets/partner-logos/NorthropRealty.png'
import NYFW from '../assets/partner-logos/NYFW.png'
import PACareerLink from '../assets/partner-logos/PACareerLink.png'
import Salute2Suit from '../assets/partner-logos/Salute2Suit.png'
import HowardCountyChamber from '../assets/partner-logos/HowardCountyChamber.png'
import LaJoyPlans from '../assets/partner-logos/LaJoyPlans.png'

export default function CompanyPartners() {
    return (
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className='w-full text-center text-gray-400 mb-12'>Latest Sieff Studios Collaborations:</div>
          <div className="flex flex-wrap w-full flex-grow justify-center items-center">
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={TWT_REV} alt="The Washington Tattoo" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={DCModernLuxury} alt="DCModernLuxury" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={Sheffield} alt="Sheffield Institute of Recording Arts" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={NBC} alt="NBC" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={NorthropRealty} alt="NorthropRealty"/>
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={NYFW} alt="NYFW" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={PACareerLink} alt="PACareerLink" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={Salute2Suit} alt="Salute2Suit" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={HowardCountyChamber} alt="HowardCountyChamber" />
            </div>
            <div className="grow m-6 flex items-center justify-center">
              <img className="h-12" src={LaJoyPlans} alt="LaJoyPlans"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
  