
import { Fragment, useState, useEffect } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";
import SmallBlackLogo from '../assets/img/SmallBlackLogo.png'

import useDebouncedEffect from '../hooks/useDebouncedEffect';



const navigation = [
  { name: 'Services', href: '/services', current: true },
  { name: 'Contact', href: '/contact', current: false }, 
  { name: 'About', href: '/about', current: false },
  { name: 'Home', href: '/', current: false },
]

const navItemClass = 'px-6 py-2 hover:text-gray-500'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({showVideo}) {


  const [offset, setOffset] = useState(0);
  const [navbarIsVisible, setNavbarIsVisible] = useState(true);
  const [hideNavbar, setHideNavbar] = useState(false)

  const [hideNavbarTrigger, setHideNavbarTrigger] = useState(false)
  
      
  useDebouncedEffect(() => {
      setHideNavbarTrigger(!hideNavbarTrigger);
  }, [navbarIsVisible], 1000)

  useEffect(() => {
      if (!navbarIsVisible) setHideNavbar(true);
  }, [hideNavbarTrigger])

  useEffect(() => {
      if (!showVideo) {
        setNavbarIsVisible(true)
      } else {

        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);

      }

  }, []);

  useEffect(() => {
    if (offset < 1 && !hideNavbar && !showVideo) {
      setNavbarIsVisible(true)
    } else { 
      if (navbarIsVisible && !showVideo) {
          setNavbarIsVisible(true)
      }
    }
  }, [offset])

  const [containerClassName, setContainerClassName] = useState('transition-all opacity-100 duration-1000')
  
  useEffect(() => {
      if (navbarIsVisible) {
          setContainerClassName('transition-all opacity-100 duration-1000 z-20')
      } else {
          setContainerClassName('transition-all opacity-0 duration-1000 z-10')
      }
  }, [navbarIsVisible])
  
  useEffect(() => {
    console.log('SHOWVIDEO,',showVideo)
    if (!showVideo) {
      setNavbarIsVisible(true)
    }
  }, [showVideo])
  
  return (
    <div className={containerClassName}>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-20 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="fixed top-0 px-4 py-3 sm:px-6 lg:px-8 z-20 w-full bg-white shadow-sm">
              <div className="relative flex justify-between w-full px-0 md:px-12 xl:px-36">
                <div className="flex lg:static xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    <a href="/">
                      <img
                        className="block h-8 w-auto"
                        src={SmallBlackLogo}
                        alt="studio-logo"
                      />
                    </a>
                  </div>
                </div>
                
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  <div className='flex'>
                    <div className={navItemClass}>
                    <Link to="/services">Services</Link>
                    </div>
                    <div className={navItemClass}>
                    <Link to="/contact">Contact</Link>
                    </div>
                    <div className={navItemClass}>
                    <Link to="/about">About</Link>
                    </div>
                    <Link to="/book">
                    <div className='bg-transparent
                      border
                      border-sieffblue
                      text-sieffblue
                      px-2
                      ml-4
                      py-2
                      flex
                      items-center
                      justify-center
                      rounded-md
                      hover:bg-sieffblue
                      hover:text-white
                      cursor-pointer
                      transition-all
                      duration-300
                    '>
                    Book Consultation
                    </div></Link>
                  </div>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" aria-label="Global">
                <div className="mt-16 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                  {navigation.map((item) => (
                    <Link
                    key={item.name}
                    to={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </Link>
                  ))}
                </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}